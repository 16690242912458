<template>
  <div>
    <div id="houwse" :style="homeBg">
      <v-container>
        <v-row align-content="start" class="mt-8 mb-4">
          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="8"
            align-self="center"
            class="mt-6"
          >
            <h3
              class="text-h3 font-weight-black white--text mb-2"
              style="width: 80%"
            >
              Second Chance Initiative
            </h3>
            <div>
              <h4 class="text-h5 grey--text text--lighten-4 mb-4">
                City of Refuge recently received a United States Department of
                Justice Second Chance Initiative grant to provide an integrated
                program to improve outcomes following reentry for returning
                citizens.
              </h4>
              <h4 class="text-h4 font-weight-medium white--text mt-10 mb-2">
                A Partnership of Excellence
              </h4>
              <p class="white--text">
                The <b>Reentry Hub</b> is a collaborative effort bringing
                multiple organizations together to provide key programs and
                services to persons before and after release from prison.
              </p>
              <v-card class="mx-auto mt-8" color="#29526b" dark>
                <v-card-title>
                  <span class="title font-weight-light"
                    >Reducing Recidivism Together</span
                  >
                </v-card-title>
                <v-card-text class="headline">
                  "We are motived and honored every day to help formerly
                  incarcerated men and women return to a thriving life."
                </v-card-text>

                <v-card-actions>
                  <v-list-item class="grow">
                    <v-list-item-avatar size="100" color="grey darken-3">
                      <v-img
                        class="elevation-6"
                        alt=""
                        src="../assets/greg-washington.jpg"
                      ></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title
                        ><b>Greg Washington</b><br />City of
                        Refuge</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card-actions>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container>
      <v-row align-content="center" class="mt-3 mb-5 mx-auto">
        <v-col cols="6" lg="3" md="3">
          <v-img
            class="mx-2 mt-1"
            src="../assets/cor-logo-bw.png"
            max-width="160"
            contain
          ></v-img>
        </v-col>
        <v-col cols="6" lg="3" md="3">
          <v-img
            class="mx-2 mt-1"
            src="../assets/tyro-logo-bw.png"
            max-width="160"
            contain
          ></v-img>
        </v-col>
        <v-col cols="6" lg="3" md="3">
          <v-img
            class="mx-2 mt-1"
            src="../assets/ulga-logo-bw.png"
            max-width="160"
            contain
          ></v-img>
        </v-col>
        <v-col cols="6" lg="3" md="3">
          <v-img
            class="mx-2 mt-1"
            src="../assets/oaa-logo-bw.png"
            max-width="160"
            contain
          ></v-img>
        </v-col>
        <!-- <v-col cols="6" lg="2" md="2">
          <v-img
            class="mx-2 mt-1"
            src="../assets/ars-logo-bw.png"
            max-width="160"
            contain
          ></v-img>
        </v-col> -->
        <!-- <v-col cols="6" lg="2" md="2">
          <v-img
            class="mx-2 mt-1"
            src="../assets/restoration-logo.png"
            max-width="160"
            contain
          ></v-img>
        </v-col> -->
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import Masthead from '@/components/Masthead'
// import UserService from '../services/UserService'
// import UserService from '../services/UserService'

export default {
  name: 'Home',
  components: {
    // Masthead
  },
  data: function() {
    return {
      node: process.env.VUE_APP_NODE_BASE_URL,
      transparentNav: true,
      user: '',
      claims: '',
      userName: '',
      // isLoggedIn: this.$store.state.isLoggedIn,
      authenticated: false,
      dialog: false,
      errorDescription: '',
      images: ['shawnee-green.jpg', 'joice-kelly-blue.jpg'],
      selectedImage: null
    }
  },
  created() {
    this.selectedImage = this.randomItem(this.images)
  },
  mounted() {},
  computed: {
    homeBg() {
      return {
        'background-image': `url(${require('@/assets/' + this.selectedImage)})`,
        'background-position': 'center',
        'background-repeat': 'no-repeat',
        'background-attachment': 'scroll',
        'background-color': '#000000',
        'background-size': 'cover'
      }
    }
  },
  methods: {
    randomItem(items) {
      return items[Math.floor(Math.random() * items.length)]
    }
  }
}
</script>
<style lang="scss">
#house {
  background-color: linear-gradient(
    to right,
    rgba(41, 82, 107, 0.8) 50%,
    rgba(41, 82, 107, 0.8) 50%,
    rgba(41, 82, 107, 0.8) 50%
  );
  // url('../assets/shawnee-unsplash.jpg');
  // url('../assets/joice-kelly-PFN8tB5iGEA-unsplash-r.jpg');
  // backgroundImage: `url(${require('@/assets/' + selectedImage)})`,

  // RGBA(41, 82, 107, 1.00)

  // background: linear-gradient(
  //     to right,
  //     rgba(24, 24, 24, 0.7) 50%,
  //     rgba(24, 24, 24, 0.7) 50%,
  //     rgba(24, 24, 24, 0.7) 50%
  //   ),
  //   url('../assets/joice-kelly-PFN8tB5iGEA-unsplash-r.jpg');
  // url('../assets/aubrey-odom-ITzfgP77DTg-unsplash.jpg');
  // background: linear-gradient(
  //     to right,
  //     rgba(24, 24, 24, 0.5) 0%,
  //     rgba(24, 24, 24, 0.8) 50%,
  //     rgba(24, 24, 24, 1) 100%
  //   ),
  //   url('../assets/joice-kelly-PFN8tB5iGEA-unsplash.jpg');

  // from Delta
  /*background-color: linear-gradient(125deg, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.5) 10%,rgba(0,0,0,0.5) 38%,rgba(0,0,0,0.5) 54%,rgba(0,0,0,0) 81%,rgba(0,0,0,0) 100%)
  */
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
}
.v-parallax__image-container {
  position: relative !important;
}
.v-parallax__content {
  flex-direction: row !important;
}
.v-application .grey-link a {
  color: #444444 !important;
  border-bottom: 1px dotted #999999;
}
</style>
